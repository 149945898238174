/* src/styles/BookList.css */

.booklist-container {
    padding: 40px 20px;
    background-color: #f0f4f8;
    font-family: Arial, sans-serif;
    color: #333;
}

.header-section {
    text-align: center;
    margin-bottom: 40px;
}

.header-section h2 {
    font-size: 2.5rem;
    color: #333;
    font-weight: bold;
}

.header-section p {
    color: #666;
    font-size: 1.1rem;
}

.books-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.book-card {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
}

.book-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.book-image-placeholder {
    background-image: linear-gradient(135deg, #6a0dad, rgb(28, 145, 204));
    /* Purple to Blue gradient */
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    /* Change text color to white for better contrast */
    font-size: 1.5rem;
    border-radius: 8px;
    /* Optional: Add rounded corners */
}


.book-content {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.book-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.book-description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.book-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    margin-top: auto;
}

.book-author {
    display: flex;
    align-items: center;
}

.author-avatar {
    background-color: #e0e0e0;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.book-price {
    color: #1a8917;
    font-weight: bold;
    font-size: 1rem;
}

.add-to-cart-button {
    background-color: rgb(28, 145, 204);
    color: white;
    padding: 10px;
    border: none;
    width: 100%;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.add-to-cart-button:hover {
    background-color: rgb(28, 145, 204);
}

/* Loading Spinner */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top: 4px solid #333;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Error Container */
.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.error-message {
    color: #b00020;
    padding: 16px;
    border: 1px solid #b00020;
    border-radius: 8px;
    background-color: #ffebee;
}