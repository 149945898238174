/* src/components/LoginRegister.css */

/* Container styling */
.login-register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
}

/* Logo section styling */
.logo-section {
    width: 40%;
    background-color: #0099ff;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.logo {
    font-size: 60px;
    margin-bottom: 10px;
}

.logo-section p {
    font-size: 18px;
    margin-top: 10px;
}

/* Form section styling */
.form-section {
    width: 60%;
    background-color: white;
    padding: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.form-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.form-header h2 {
    cursor: pointer;
    font-weight: normal;
    color: gray;
    border-bottom: 2px solid transparent;
    transition: all 0.3s;
}

.form-header h2.active {
    color: #0099ff;
    font-weight: bold;
    border-bottom: 2px solid #0099ff;
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 14px;
    color: gray;
    margin-top: 10px;
}

input {
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.form-footer a {
    color: #0099ff;
    text-decoration: none;
    font-size: 14px;
}

.form-footer button {
    background-color: #0099ff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-footer button:hover {
    background-color: #007acc;
}
