/* src/styles/Home.css */

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f9fc;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    width: 100%;
    padding-top: 60px;
    /* Adjust padding to create space for the navbar */
}

/* Navbar Styling */
.navbar {
    width: 100%;
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.navbar-links p {
    margin: 0;
    font-size: 1rem;
}

.logout-button {
    background-color: #dc3545;
    color: white;
    padding: 8px 16px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #c82333;
}

/* Booklist Section */
.booklist-section {
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}


/* Booklist Link Button */
.booklist-link {
    background-color: #007bff;
    margin-top: 15px;
}

.booklist-link:hover {
    background-color: #0056b3;
}


/* Booklist Section within Home */
.booklist-section {
    width: 100%;
    max-width: 1200px;
}